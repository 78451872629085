import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { IonicStorageModule } from '@ionic/storage-angular';
import { enterAnimation } from './animations/nav-animation';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes/pipes.module';
import { NgxStripeModule } from 'ngx-stripe';
import { MarkdownModule } from 'ngx-markdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            navAnimation: enterAnimation
        }),
        MarkdownModule.forRoot(),
        AppRoutingModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFunctions(() => {
            const functions = getFunctions(getApp(), "us-central1");
            return functions;
        }),
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
        }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        IonicStorageModule.forRoot({
            name: 'cwdb'
        }),
        HttpClientModule,
        PipesModule,
        NgxStripeModule.forRoot('pk_test_51LlsVrLlIJdDmLNcCdBjuZxBFUrJBj5iPDgudHn1CHZYwWkH57aXife2j0sRhrZ7h9VvRAr1wMLFjzb9ZAQRY7Rz00tzqEPpDp'),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy,}
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule { }
