import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CredentialsModel } from '../model/credentials.model';
import { Profile } from '../model/profile.model';
import { UserModel } from '../model/user.model';

const BUCKET = "profile-images";
const DEFAULT_PROFILE_PIC = '/assets/images/user.png';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  supabase: SupabaseClient;
  private _currentUser: BehaviorSubject<UserModel | boolean | any> = new BehaviorSubject(null);
  userId: string | undefined;
  private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);
  private _requested: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _auth: Subject<UserModel | boolean | any> = new Subject();

  constructor(private toastController: ToastController,
    private router: Router,) {

    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey, {
      auth: {
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: true
      }
    });

    this._requested.next(false);

    this.supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == 'SIGNED_IN' || event == 'PASSWORD_RECOVERY') {

        // user has singed in so reset the magic link requested flag. 
        this.userId = session.user.id;
        console.log("signedin", session.user, this.userId);
        const user = {
          ...session.user,
          event: event
        }

        this.getProfile();

        this._currentUser.next(user);
        this._auth.next(user);

        if (event == 'PASSWORD_RECOVERY') {
          this.router.navigate(['/set-password']);
        }
      } else {
        console.log("event", event, session);
        this.userId = null;
        this._currentUser.next(false);
        this._profile.next(null);
        this._auth.next(null);

      }
    });
  }

  async getProfile() {
    if (!this.userId) {
      return;
    }
    const { data, error } = await this.supabase
      .from('profiles')
      .select('profile_id, created_at, first_name, last_name, profile_photo_url, profile_public_photo_url, email, username, phone, phone_verified')
      .eq('profile_id', this.userId)
      .limit(1)
      .maybeSingle();

    if (error) {
      throw new Error('Failed to get profile');
    }

    if (data) {
      console.log("data", data);
      if (!data.profile_public_photo_url) {
        data.profile_public_photo_url = '/assets/images/user.png';
      }
      const profile: Profile = structuredClone(data);
      if (!profile.first_name && !profile.last_name) {
        profile.initials = "?";
      }
      else {
        profile.initials = profile.first_name[0].toUpperCase() + profile.last_name[0].toUpperCase();
      }
      this._profile.next(profile);
    }
  }

  async isUser() {
    const session = await this.supabase.auth.getSession();
    if (session?.data?.session?.user) {
      this._currentUser.next(session.data.session.user);
    } else {
      this._currentUser.next(false);
    }
  }

  async signUp(credentials: { email, password }) {
    const res = await this.supabase.auth.signUp(credentials);
    return res;
  }

  async signIn(credentials: { phone }) {

    const res = await this.supabase.auth.signInWithOtp({ phone: credentials.phone });
    this._requested.next(true);
    return res;

  }

  async signInWithPassword(credentials: { email, password }) {

    const res = await this.supabase.auth.signInWithPassword(credentials);
    this._requested.next(true);
    return res;

  }

  async confirmationCode(credentials) {
    const res = await this.supabase.auth.verifyOtp({ phone: credentials.phone, token: credentials.token, type: credentials.type });
    return res;
  }

  signOut() {
    this.supabase.auth.signOut().then(async _ => {
      this.router.navigateByUrl('/login', { replaceUrl: true });
      this.supabase.getChannels().map(sub => {
        this.supabase.removeChannel(sub);
        this._currentUser.next(false);
        this._requested.next(false);
      });

      let toast = await this.toastController.create({
        duration: 3000,
        message: `Good bye, come back soon.`
      });
      toast.present();

    });

  }

  resetPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email);
  }

  get userEmail() {
    return (this._currentUser.value as UserModel).email;
  }

  get currentUser(): Observable<UserModel | boolean> {
    console.log("current user", this._currentUser.value);
    return this._currentUser.asObservable();
  }

  get requested(): Observable<boolean> {
    return this._requested.asObservable();
  }

  get session() {
    return this.supabase.auth.getSession();
  }

  public updatePassword = async (new_password: string) => {
    const { error, data } = await this.supabase.auth.updateUser({ password: new_password });
    return { error, data };
  }

  getUser() {
    return this._currentUser.asObservable();
  }

  // profile

  // add a user.
  async addProfile(profile: Profile) {
    return await this.supabase.from('profile').insert(profile);
  }

  async updateProfile(profile: Profile) {
    const profile_id = this.userId;

    if (profile_id) {

      // has the email address changed?
      if (this.userEmail != profile.email) {
        // email has changed to send email to confirm change.
        const { data, error } = await this.supabase.auth.updateUser({ email: profile.email });
      }

      if (this._profile.value.username != profile.username) {
        const { data, error } = await this.supabase
          .from('profiles')
          .update({
            username: profile.username,
          })
          .match({ profile_id });
        if (error) {
          if (error.message.includes('profile_username_key')) {
            throw new Error(`Username '${profile.username}' is unavailable.  Please choose another.`)
          }
        }
      }

      const { data, error } = await this.supabase
        .from('profiles')
        .update({
          first_name: profile.first_name,
          last_name: profile.last_name,
        })
        .match({ profile_id });

      if (error) {
        throw new Error('Failed to update profile.  Please try again.')
      }

      // only update the fields that might have changed.
      const newProfile = this._profile.value;
      newProfile.first_name = profile.first_name;
      newProfile.last_name = profile.last_name;
      newProfile.email = profile.email;
      newProfile.username = profile.username;

      this._profile.next(newProfile);
      return data;
    }
    return null;
  }

  async updateProfilePhoto(photoUrl: string) {

    const profile_id = this.userId;
    if (profile_id) {

      const publicUrl = this.supabase.storage.from(BUCKET).getPublicUrl(photoUrl);
      const res = await this.supabase
        .from('profile')
        .update({
          profile_photo_url: photoUrl,
          profile_public_photo_url: publicUrl.data.publicUrl,
        })
        .match({ profile_id });
      this._profile.value.profile_photo_url = photoUrl;
      const ts = '?ts=' + (new Date()).getTime();
      this._profile.value.profile_public_photo_url = publicUrl.data.publicUrl + ts;
      this._profile.next(this._profile.value);
      return res;
    }
    return null;
  }

  get profile(): Observable<Profile> {
    return this._profile.asObservable();
  }

  get profileId() {
    return this._profile.value.profile_id;
  }

  async isDuplicateUsername(username: string): Promise<number> {
    if (!username) {
      throw new Error("Cannot check a blank username");;
    }

    const { error, count } = await this.supabase
      .from('profiles')
      .select('*', { count: 'exact' })
      .eq('username', username);

    if (error) {
      throw new Error('Failed to check duplicate username.');
    }

    return count;
  }

  async checkInvitationCode(code: string) {
    try {
      console.log(code);
      const { data, error } = await this.supabase.functions.invoke('check-invitation-code', { body: JSON.stringify({ code: code }) });
      console.log( {data, error} );
      if (!error) {
        return data;
      } else {
        return {error: 'The invitation code may longer be valid or it has expired.'}
      }
    } catch (error) {
      return {error: 'The invitation code may longer be valid or it has expired.'}
    }
  }

  async registerWithInviteCode(credentials: CredentialsModel) {

    try {
      const { data, error } = await this.supabase.functions.invoke('register-with-invitation-code', { body: JSON.stringify(credentials) });

      if (!error) {
        console.log("data", data);
        return data;
      } else {
        console.log("Supabase error", error);

        // Access the status code and error message
        const statusCode = error.details?.status;
        const errorMessage = error.details?.message;

        console.log(`Error status code: ${statusCode}`);
        console.log(`Error message: ${errorMessage}`);

        return {error: errorMessage};
      }
    } catch (error) {
      console.log("error")
      return {error: 'Unable to register an account with these details.  Please try again. If the probelm persists, please contact support.' }
    }


  }

}
