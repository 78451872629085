import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';

@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {

  transform(value: Date): string {
    const date = new Date(value);
    return formatDistance(date, new Date(), { addSuffix: true }); 
  }

}
