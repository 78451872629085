import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { RolesService } from './services/roles.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];
  public labels = [];

  constructor(
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private alertCtrl: AlertController,
    private router: Router,
    private rolesService: RolesService) {
    this.checkUpdate();

    this.authService.currentUser.subscribe(user => {
      if (user) {
        this.appPages = [
          { title: 'Marketplace', url: 'marketplace', icon: 'megaphone' },
          { title: 'Newsletter', url: 'newsletter', icon: 'mail' },
          { title: 'Calendar', url: 'calendar', icon: 'calendar' },
          { title: 'Profile', url: 'profile', icon: 'person' },
          { title: 'Pricing', url: 'pricing', icon: 'card' },
          { title: 'About', url: 'about', icon: 'information-circle' },
          { title: 'Contact us', url: 'contact-us', icon: 'chatbubbles' },
          { title: 'Sign out', url: 'logout', icon: 'log-out' },
        ]
      } else {
        this.appPages = [
          { title: 'Marketplace', url: 'marketplace', icon: 'megaphone' },
          { title: 'Newsletter', url: 'newsletter', icon: 'mail' },
          { title: 'Pricing', url: 'pricing', icon: 'card' },
          { title: 'About', url: 'about', icon: 'information-circle' },
          { title: 'Contact us', url: 'contact-us', icon: 'chatbubbles' },
          { title: 'Sign in', url: 'login', icon: 'log-in' },
          { title: 'Register', url: 'register', icon: 'person-add' },
        ];
      }
    });

    // handle password recovery links
    const hash = window.location.hash;
    if (hash && hash.substr(0, 1) === '#') {
      const tokens = hash.substr(1).split('&');
      const entryPayload: any = {};
      tokens.map((token) => {
        const pair = (token + '=').split('=');
        entryPayload[pair[0]] = pair[1];
      });
      if (entryPayload?.type === 'recovery') { // password recovery link
        this.router.navigateByUrl(`/set-password/${entryPayload.access_token}`);
      }
    }



  }

  checkUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(async (event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
              console.log(`Downloading new app version: ${event.version.hash}`);
              break;
          case 'VERSION_READY':
              console.log(`Current app version: ${event.currentVersion.hash}`);
              console.log(`New app version ready for use: ${event.latestVersion.hash}`);
              const alert = await this.alertCtrl.create({
                header: 'Refresh website',
                message: 'A new version of this website is available.  Please click Refresh to continue.',
                buttons: [
                  {
                    text: 'Refresh',
                    handler: async () => {
                      await this.swUpdate.activateUpdate();
                      location.reload();
                    },
                  },
                ],
              });
      
              await alert.present();

              break;    
          case 'VERSION_INSTALLATION_FAILED':
              console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
              break;
      }




      });
    }
  }

}
