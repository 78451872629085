import { Component } from '@angular/core';

@Component({
    selector: 'app-external-url',
    template: `<div>Opening external page.</div>`,
})
export class ExternalUrlComponent { 
    constructor() { 
        history.go(-1);
    }
}
