import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ExternalUrlComponent } from './components/external-url.component';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: ExternalUrlComponent,
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'set-password/:token',
    loadChildren: () => import('./pages/set-password/set-password.module').then( m => m.SetPasswordPageModule)
  },
  {
    path: 'introduction',
    loadChildren: () => import('./pages/introduction/introduction.module').then( m => m.IntroductionPageModule)
  },
  {
    path: 'welcome-video',
    loadChildren: () => import('./pages/welcome-video/welcome-video.module').then( m => m.WelcomeVideoPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'social-learn-more',
    loadChildren: () => import('./pages/social-learn-more/social-learn-more.module').then( m => m.SocialLearnMorePageModule)
  },
  {
    path: 'report-post',
    loadChildren: () => import('./pages/report-post/report-post.module').then( m => m.ReportPostPageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then( m => m.PricingPageModule)
  },
  {
    path: 'filter-posts-dialog',
    loadChildren: () => import('./pages/filter-posts-dialog/filter-posts-dialog.module').then( m => m.FilterPostsDialogPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./pages/image-viewer/image-viewer.module').then( m => m.ImageViewerPageModule)
  },
  {
    path: 'join-group',
    loadChildren: () => import('./pages/join-group/join-group.module').then( m => m.JoinGroupPageModule)
  },
  {
    path: 'subscription-success',
    loadChildren: () => import('./pages/subscription-success/subscription-success.module').then( m => m.SubscriptionSuccessPageModule)
  },
  {
    path: 'subscription-cancel',
    loadChildren: () => import('./pages/subscription-cancel/subscription-cancel.module').then( m => m.SubscriptionCancelPageModule)
  },
  {
    path: 'business-profile',
    loadChildren: () => import('./pages/business-profile/business-profile.module').then( m => m.BusinessProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filter-news-dialog',
    loadChildren: () => import('./pages/filter-news-dialog/filter-news-dialog.module').then( m => m.FilterNewsDialogPageModule)
  },
  {
    path: 'login-otp',
    loadChildren: () => import('./pages/login-otp/login-otp.module').then( m => m.LoginOtpPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'messages-and-notifications',
    loadChildren: () => import('./pages/messages-and-notifications/messages-and-notifications.module').then( m => m.MessagesAndNotificationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotions-and-offers',
    loadChildren: () => import('./pages/promotions-and-offers/promotions-and-offers.module').then( m => m.PromotionsAndOffersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'treasure-hunt',
    loadChildren: () => import('./pages/treasure-hunt/treasure-hunt.module').then( m => m.TreasureHuntPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events-calendar',
    loadChildren: () => import('./pages/events-calendar/events-calendar.module').then( m => m.EventsCalendarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'count/:token',
    loadChildren: () => import('./pages/count/count.module').then( m => m.CountPageModule)
  },
  {
    path: 'counters',
    loadChildren: () => import('./pages/counters/counters.module').then( m => m.CountersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'live-stream',
    loadChildren: () => import('./pages/live-stream/live-stream.module').then( m => m.LiveStreamPageModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-image',
    loadChildren: () => import('./pages/profile-image/profile-image.module').then( m => m.ProfileImagePageModule),
    canActivate: [AuthGuard],
  }, 
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },



];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_blank');
      },
    },
  ],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
