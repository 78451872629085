import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(sentence : string, amount : number, tail: string): string {
    if (!sentence) {
        return '';
      }
  
      const words = sentence.split(' ');
  
      if (amount >= words.length) {
        return sentence;
      }
  
      const truncated = words.slice(0, amount);
      return `${truncated.join(' ')}${tail}`;
  }
}
