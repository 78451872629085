import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) : Observable<boolean | UrlTree> {
    console.log("auth guard");
    const res = this.authService.currentUser.pipe(
      take(1),
      switchMap(user => {
        if (user) {
          return this.authService.profile.pipe(
            filter(val => val !== null),
            take(1),
            map(profile => {
              const isAuth = !!user && !!profile?.phone;
              console.log("isAuth", isAuth);
              if (isAuth) {
                return true;
              } else {
                console.log("user", user);

                if (route?.routeConfig?.path == 'profile' || route?.routeConfig?.path == 'logout') {
                  return true;
                } else {
                  return this.router.createUrlTree(['/profile']);
                }
              }
            })
          );
        } else {
          console.log("Continue to current URL!");
          return of(this.router.createUrlTree(['/login']));
        }
      })
    );

    return res;
  }
}
