import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns'; 

@Pipe({
  name: 'dateformat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, dateFormat: string): string {
    const date = new Date(value);
    return format(date, dateFormat);
  }

}
