import { Injectable } from '@angular/core';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../model/user.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  
  private supabase: SupabaseClient;
  private _roles: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private roleList: Array<string> = [];
  
  constructor(private authService: AuthService,) {
    this.supabase = this.authService.supabase;

    this.roles.subscribe(roles => {
      console.log("roles", roles);
      this.roleList = roles;
    });

    this.authService.currentUser.subscribe(user => {
      if (!user) {
        this.roleList = [];
        this._roles.next(null);
      } else {
        if (this.isUser(user))
          this.getRoles(user.id);
      }
    });
  }

  isUser(val: boolean | UserModel): val is UserModel {
    return (val as UserModel).id !== undefined;
  }

  get roles(): Observable<string[]> {
    return this._roles.asObservable();
  }


  async getRoles(userId: string) {
    console.log("get roles", userId);
    if (userId) {
      const query = await this.supabase.from('user_roles').select('*').eq('user_id', userId);
      this._roles.next(query.data);
    }

  }

  isAdmin(): boolean {
    let isAdmin = false;
    console.log("roles", this.roleList);
    this.roleList?.forEach((role : any)=> {
      if (role.role == 'admin') {
        isAdmin = true;
      }
    })
    return isAdmin;
  }

}
