// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'colchester-website',
    appId: '1:998209067698:web:bb310724bf9504873cc7ab',
    storageBucket: 'colchester-website.appspot.com',
    apiKey: 'AIzaSyBWj4pxYlJrQFSdkswaCcKBb9bq74Z7shM',
    authDomain: 'colchester-website.firebaseapp.com',
    messagingSenderId: '998209067698',
    measurementId: 'G-BDXXK6ZZBQ',
  },
  production: false,
  supabaseUrl: 'https://fbiujftqklqgnoqtcksg.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZiaXVqZnRxa2xxZ25vcXRja3NnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDY5MjQxNzUsImV4cCI6MTk2MjUwMDE3NX0.KOZtWHKb7c5gPOkWdpVl9szddLzsVHcRBp8YEmJS1NA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
